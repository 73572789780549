@import 'src/styles/variables';
@import 'src/styles/functions';
@import 'src/styles/mixins';

.wrap {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 10;
  display: none;
  background: color('mainwhite');

  @media (min-width: $mobile-size) {
    position: static;
    display: flex;
    align-items: center;
    min-height: 56px;
  }

  @media (min-width: $desktop-size) {
    min-height: 80px;
  }
}

.container {
  display: flex;
  align-items: center;
  padding: 0;

  a {
    display: flex;
    align-items: center;
  }
}

.logoWrap {
  display: flex;
  align-items: center;
  gap: spacing(l);
}

.city {
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  color: color(main);
  font-size: 14px;
  @include use-spacings(margin-right, 24px);

  &:hover {
    color: color(buttonhover);
  }
}

.logo {
  height: 32px;

  svg {
    overflow: visible;
  }
}

.menuList {
  display: none;

  @media (min-width: $desktop-size) {
    display: flex;
    align-items: center;
  }
}

.controls {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.phone {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  a {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  @media (min-width: $tablet-size) {
    min-width: 172px;
  }

  @media (min-width: $desktop-size) {
    min-width: min-content;
  }
}

.mobileButton {
  @include use-spacings(margin-left, 32px);

  height: 32px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;

  @include use-spacings(padding, 8px, 32px);

  @media (min-width: $tablet-size) {
    display: none;
  }

  @media (min-width: $desktop-size) {
    display: block;
    height: 44px;
  }
}

.burger {
  display: flex;
  align-items: center;

  @include use-spacings(margin-left, 8px);

  @media (min-width: $desktop-size) {
    display: none;
  }
}
