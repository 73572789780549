@import 'src/styles/functions';
@import 'src/styles/variables';
@import 'src/styles/mixins';

.nav {
  height: 100%;

  @include flex();
  @include use-spacings(margin-left, 5%);
}

.nav-item-wrap {
  @include small-text();

  position: relative;
  height: 100%;

  &:not(:last-child) {
    @include use-spacings(margin-right, m);
  }
}

.nav-item {
  @include flex();

  height: 100%;

  &:hover {
    color: color('main');
  }

  a {
    // line-height: 60px;
  }
}

.link {
  &:hover {
    color: color('main');
  }
}

.nav-with-submenu {
  @include flex();
}

.nav-active {
  color: color(main);
}

.arrow {
  width: 10px;
  height: 6px;
  margin-top: 2px;

  @include use-spacings(margin-left, 'xs');
}

[data-submenu-active='true'] {
  .nav-item {
    color: color('main');
  }

  .arrow {
    transform: rotate(180deg);
  }
}
